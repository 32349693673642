import React from 'react';
import axios from 'axios';
import Config from "../services/config";

export default class UploadCSV extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            records: []
        };
        this.doUpload = this.doUpload.bind(this);
    }

    doUpload(e) {
        e.preventDefault()
        if (e.target.files.length > 0) {
            let reader = new FileReader()
            let file = e.target.files[0]
            reader.onloadend = () => {

                let csvFile = {
                    content: reader.result
                };

                axios.post('/api/v3/radio/csv', csvFile, Config.getHeader()).then(data => {
                    this.setState({
                        records: data.data
                    });
                }, error => {
        
                })

            }
            reader.readAsDataURL(file)
        }
    }

    render() {

        let f = function(s) {
            return (s || "").substr(0, 100)
        }

        let rows = [];
        this.state.records.forEach(rec => {
            let tr1 = <tr key={rec.rowIndex + "_1"}>
            <td><b>Наименование</b> {rec.name}<br/>
                <b>Страна</b> {rec.country}<br/>
                <b>Город</b> {rec.city}<br/>
                <b>Жанр</b> {rec.genre}<br/>
                <b>Сайт</b> {rec.site}</td>
            <td>{rec.stream1}<br/>
                {rec.stream2}<br/>
                {rec.stream3}<br/>
                {rec.stream4}</td>            
            <td>{rec.tags}</td>
            </tr>

            let tr2 = <tr key={rec.rowIndex + "_2"}>
                <td colSpan={3}>{f(rec.description)}</td>
            </tr>        

            rows.push(tr1)
            rows.push(tr2)
        })

        return <div>
            <section className="content-header">
                <h1>
                    Добавление CSV
                </h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-header">
                        <form className="form-horizontal">
                            <div className="form-group">
                                <label className="col-sm-2 control-label">CSV</label>
                                <div className="col-sm-3">
                                    <label className="btn btn-info">
                                        <input type="file" hidden onChange={this.doUpload} /> Загрузить
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="box-body table-responsive">
                        <table className="table table-bordered table-condensed table-hover">
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    }

}