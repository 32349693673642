import React from 'react';
import axios from 'axios';
import Config from "../services/config";
import { Link } from "react-router-dom";

export default class LocaleList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            localeLangs: [],
            record: {
                id: 0,
                code: '',
                name: '',
                appendable: true
            }
        };
        this.save = this.save.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    componentDidMount() {
        this.search();
    }

    search() {

        axios.get('/api/v3/localeLang', Config.getHeader()).then(data => {
            let rowsWithLast = data.data.concat([this.getEmptyRecord()]);
            this.setState({
                localeLangs: rowsWithLast                
            });            
        }, error => {

        });
    }

    getEmptyRecord() {
        return {
            id: 0,
                code: '',
                name: '',
                appendable: true
        };
    }

    save(e) {
        let req = this.state.record;
        axios.post('/api/v3/localeLang', req, Config.getHeader()).then(data => {
            this.setState({
                record: {
                    id: 0, code: '', name: '', appendable: true
                }
            });
            this.search();
        }, error => {

        });
    }

    onEdit(e) {
        let id = e.target.id;
        let record = this.state.record;

        switch(id) {
            case 'locale_code':
                record.code = e.target.value
                break;
            case 'locale_name':
                record.name = e.target.value
                break;
            default:
                break;
        }

        this.setState({record: record});

    }

    render() {
       

        let rows = this.state.localeLangs.map(localeLang => {
            if (localeLang.appendable) {
                return <tr key={-1}>
                    <td><input type="text" className="form-control" onChange={this.onEdit} id="locale_code" /></td>
                    <td><input type="text" className="form-control" onChange={this.onEdit} id="locale_name"  /></td>
                    <td><button className="btn btn-success" onClick={this.save}><i className="fa fa-save"></i></button></td>
                </tr>
            } else {
                return <tr key={localeLang.id}>
                    <td>{localeLang.code}</td>
                    <td>{localeLang.name}</td>
                    <td><Link to={"/Locale/" + localeLang.id} className="btn btn-success btn-sm"><i className="fa fa-edit"> </i> </Link></td>
                </tr>
            }
        });

        return <div>
            <section className="content-header">
                <h1>Локализация</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th width="20%">Код</th>
                                    <th width="70%">Язык</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    }


}
