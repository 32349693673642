import React from 'react';
import axios from 'axios';
import Config from "../services/config";
import Modal from "react-modal";
import modalStyle from "../services/consts";
import {Link} from "react-router-dom";

export default class GenreList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            genres: [],
            openEditDialog: false,
            openDropDialog: false,
            genre: {
                id: 0,
                name: ''
            }
        }
        this.onEdit = this.onEdit.bind(this);
        this.doSave = this.doSave.bind(this);
        this.doLoad = this.doLoad.bind(this);
        this.doShow = this.doShow.bind(this);
        this.confirmToDelete = this.confirmToDelete.bind(this);
    }

    onEdit(e) {
        let id = e.target.id || 'undef'
        let genre = this.state.genre;
        switch (id) {
            case 'genre_name':
                genre.name = e.target.value;
                break;
        }
        this.setState({
            genre: genre
        })
    }

    doSave(e) {
        e.preventDefault()
        e.stopPropagation()
        axios.post('/api/v3/genre', this.state.genre, Config.getHeader()).then(data => {
            this.setState({
                openEditDialog: false,
                openDropDialog: false,
                genre: {
                    id: 0, name: ''
                }
            })
            this.doLoad()
        })
    }

    componentDidMount() {
        this.doLoad()
    }

    doLoad() {
        axios.get('/api/v3/genre', Config.getHeader()).then(data =>
                this.setState({
                    genres: data.data
                })
            , error => {

            })
    }

    doShow(e, genre) {
        this.setState({
            genre: genre,
            openEditDialog: true
        })
    }

    doDelete(e, drop) {
        if (drop) {
            axios.delete('/api/v3/genre/' + this.state.genre.id, Config.getHeader())
                .then((data) => {
                    this.doLoad();
                })
        }
        this.setState({
            genre: {
                id: 0, name: ''
            },
            openDropDialog: false,
            openEditDialog: false
        })
    }

    confirmToDelete(e, genre) {
        this.setState({
            genre: genre,
            openDropDialog: true
        })
    }

    render() {

        let rows = this.state.genres.map(row => <tr key={row.id}>
            <td>{row.name}</td>
            <td>
                <button className="btn btn-default btn-sm" onClick={e => this.doShow(e, row)}><i className="fa fa-edit"></i> Ред.</button>
            </td>
            <td>
                <button className="btn btn-danger btn-sm" attr-id={row.id} onClick={(e) => this.confirmToDelete(e, row)}><i className="fa fa-trash"></i> Удалить.</button>
            </td>
        </tr>);

        let editDialog = undefined
        if (this.state.openEditDialog) {
            editDialog = <Modal isOpen={true} onRequestClose={(e) => {}} style={modalStyle} >
                <form className="form-horizontal">
                    <div className="form-group">
                        <label className="col-sm-4 control-label">ID</label>
                        <div className="col-sm-8">
                            <p className="form-control-static">{this.state.genre.id}</p>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-4 control-label">Наименование</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" value={this.state.genre.name} onChange={this.onEdit} id="genre_name"/>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-offset-4 col-sm-8">
                            <button className="btn btn-default" onClick={(e) => this.doDelete(e, false)}><i className="fa fa-times-circle"> </i> Отмена</button>
                            <button className="btn btn-primary" onClick={this.doSave}><i className="fa fa-save"> </i> Сохранить</button>
                        </div>
                    </div>
                </form>

            </Modal>
        }
        let dropDialog = undefined
        if (this.state.openDropDialog) {
            dropDialog = <Modal isOpen={true} onRequestClose={(e) => {}} style={modalStyle}>
                <h3>Удалить {this.state.genre.name}?</h3>
                <div className="row">
                    <div className="col-sm-6">
                        <button className="btn btn-default" onClick={(e) => this.doDelete(e, false)}>Не удалять</button>
                    </div>
                    <div className="col-sm-6">
                        <button className="btn btn-primary" onClick={(e) => this.doDelete(e, true )}>Удалить</button>
                    </div>
                </div>
            </Modal>
        }

        return <div>
            <section className="content-header">
                <h1>Список жанров
                    <div className="pull-right">
                        <button className="btn btn-success btn-sm" onClick={(e) => this.setState({openEditDialog: true})}><i className="fa fa-plus"/> Добавить</button>
                    </div>
                </h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Наименование</th>
                                <th colSpan={2} width={"1%"}></th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            {editDialog}
            {dropDialog}
        </div>
    }

}