import React from 'react';
import axios from 'axios';
import Config from "../services/config";

export default class LocaleForm extends React.Component {

    componentDidMount() {
        let id = parseInt(this.props.match.params.id || 0);
        if (id > 0) {
            axios.get('/api/v3/localeLang/terms/' + id, Config.getHeader()).then(data => {

            }, error => {

            });
        }        
    }

    render() {
        return <div></div>
    }

}