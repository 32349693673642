import React from 'react';

import { BrowserRouter as Router, Link, Route, useHistory } from 'react-router-dom';
import RadioList from "./components/RadioList";
import RadioForm from "./components/RadioForm";
import UploadCSV from './components/UploadCSV';
import GenreList from "./components/GenreList";
import CountryList from "./components/CountryList";
import LoginForm from "./components/LoginForm";
import Modal from 'react-modal';
import './App.css'
import AccountList from "./components/AccountList";
import AccountView from "./components/AccountView";
import ChangePassword from "./components/ChangePassword";
import LocaleList from './components/LocaleList';
import LocaleForm from './components/LocaleForm';

Modal.setAppElement('#root');

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      online: false,
      selectMenuItem: ''
    }

    this.onSuccessLogin = this.onSuccessLogin.bind(this);
    this.logout = this.logout.bind(this);
  }

  onSuccessLogin(e) {
    this.setState({
      online: true
    });
  }

  logout() {
    localStorage.removeItem('online');
    localStorage.removeItem('token');

    this.setState({
      online: false
    });
  }

  render() {
    let online = localStorage.getItem('online') === 'true';
    if (!online) {
      return <Router>
        <LoginForm onSuccess={this.onSuccessLogin} />
      </Router>
    }

    let topColorClass = {
      backgroundColor: '#fd3c00'
    };

    return (
      <Router>
        <div className="wrapper">
          <header className="main-header">
            <a href="../../index2.html" className="logo" style={topColorClass}>
              <span className="logo-mini"><b>RVISION.Радио</b></span>
              <span className="logo-lg"><b>RVISION.Радио</b></span>
            </a>
            <nav className="navbar navbar-static-top" style={topColorClass}>

              <div className="navbar-custom-menu">
                <ul className="nav navbar-nav">
                  <li className="dropdown messages-menu">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                      <i className="fa fa-envelope-o" />

                    </a>
                  </li>
                </ul>
              </div>

            </nav>
          </header>
          <aside className="main-sidebar">
            <section className="sidebar">
              <ul className="sidebar-menu" data-widget="tree">

                <li><Link to="/Info" >Главная</Link></li>

                <li>
                  <Link to="/RadioList" >
                    <i className="fa fa-list-ul" />
                    <span>Радио</span>
                  </Link>
                </li>
                <li>
                  <Link to="/GenreList" >
                    <i className="fa fa-list-ul" />
                    <span>Жанры</span>
                  </Link>
                </li>
                <li>
                  <Link to="/CountryList" >
                    <i className="fa fa-list-ul" />
                    <span>Страны</span>
                  </Link>
                </li>
                <li>
                  <Link to="/AccountList" >
                    <i className="fa fa-users" />
                    <span>Слушатели</span>
                  </Link>
                </li>
                <li>
                  <Link to="/ChangePassword" >
                    <i className="fa fa-user-secret" />
                    <span>Смена пароля</span>
                  </Link>
                </li>
                <li>
                  <Link to="/Image" >
                    <i className="fa fa-user-secret" />
                    <span>Редактор картинки</span>
                  </Link>
                </li>
                <li>
                  <Link to="/Locale">
                    <i className="fa fa-language" />
                    <span>Локализация</span>
                  </Link>
                </li>
                <li>
                  <a onClick={this.logout} >
                    <i className="fa fa-power-off" />
                    <span>Выход</span>
                  </a>
                </li>

              </ul>
            </section>
          </aside>
          <div className="content-wrapper">
            <Route path="/RadioList" component={RadioList} />
            <Route path="/RadioForm" component={RadioForm} exact />
            <Route path="/RadioForm/:id" component={RadioForm} />
            <Route path="/UploadCSV" component={UploadCSV} />
            <Route path="/GenreList" component={GenreList} />
            <Route path="/CountryList" component={CountryList} />
            <Route path="/AccountList" component={AccountList} />
            <Route path="/AccountView/:code" component={AccountView}/>
            <Route path="/ChangePassword" component={ChangePassword} />
            <Route path="/Locale" component={LocaleList} exact />
            <Route path="/Locale/:id" component={LocaleForm} />
          </div>
        </div>
      </Router>
    )

  }

  componentWillMount() {
    let online = localStorage.getItem('online');
    let isOnline = online === 'true' || online === true;
    this.setState({
      'online': isOnline
    });
  }

}

