
export const Config = {
    getHeader,
    getHeaderWithParams
}

function getHeader() {
    return {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    };
}

function getHeaderWithParams(filter) {
    return {
        params: filter,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    };
}

export default Config;
