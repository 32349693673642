import React from 'react';
import axios from "axios";

export default class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    onSubmit(e) {

        e.preventDefault()

        let request = {
            username: this.state.username,
            password: this.state.password
        }
        axios.post('/api/v3/login', request, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((data) => {
            localStorage.setItem('token', data.data.token);
            localStorage.setItem('online', 'true');
            this.props.onSuccess();
        }, (error) => {
            localStorage.removeItem('online');
            localStorage.removeItem('token');
        });
    }

    onEdit(e) {
        let id = e.target.id || '';
        if (id === 'login_username') {
            this.setState({
                username: e.target.value
            });
        }
        if (id === 'login_password') {
            this.setState({
                password: e.target.value
            });
        }
    }

    render() {
        return <div className="login-box">
            <div className="login-box-body">
                <p className="login-box-msg">Вход</p>

                <form>
                    <div className="form-group has-feedback">
                        <input type="text" className="form-control" value={this.state.username} onChange={this.onEdit} placeholder="Login"
                               id="login_username" required />
                        <span className="glyphicon glyphicon-envelope form-control-feedback"/>
                    </div>
                    <div className="form-group has-feedback">
                        <input type="password" className="form-control" value={this.state.password} onChange={this.onEdit} placeholder="Password"
                               id="login_password" required />
                        <span className="glyphicon glyphicon-lock form-control-feedback"/>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <button type="submit" className="btn btn-primary btn-block btn-flat" onClick={this.onSubmit}>Вход</button>
                        </div>
                    </div>
                </form>


            </div>
        </div>
    }

    render2() {
        return <div>
            <div>
                <input type="email" value={this.state.username} onChange={this.onEdit} placeholder="Login"
                       id="login_username"/>
            </div>
            <div>
                <input type="password" value={this.state.password} onChange={this.onEdit} placeholder="Password"
                       id="login_password"/>
            </div>
            <div>
                <button type="submit" onClick={this.onSubmit}>Send</button>
            </div>
        </div>
    }

}