import React from 'react';
import Config from "../services/config";
import RadioApi from "../services/RadioApi";

export default class AccountView extends React.Component {

    constructor(prop) {
        super(prop);
        this.state = {
            account: {

            },
            props: [],
            radios: []
        };
    }

    componentDidMount() {
        let _this = this;
        let id = this.props.match.params.code || false;
        if (id) {
            (async () => {
                const data = await RadioApi.getAccountInfo(id);
                _this.setState({
                    account: data.account,
                    props: data.props,
                    radios: data.radios
                });
            })();
        }
    }

    render() {

        let viewProps = this.state.props.map(p => {
            return <div key={p.id}>
                <strong>{p.prop}</strong>
                <p className="text-muted">{p.value}</p>
                <hr size="1"/>
            </div>
        });

        let radios = this.state.radios.map(r => {
            return <div key={r}>
                <strong>{r}</strong>
                <hr size="1"/>
                </div>
        });

        let regDate = new Date(this.state.account.created_at);
        let lastDate = new Date(this.state.account.last_action);

        return <div>
            <section className="content-header">
                <h1>
                    Слушатель
            </h1>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="box box-primary">
                            <div className="box-header">
                                <h3 className="box-title">Аккаунт</h3>
                            </div>
                            <div className="box-body">
                                <strong>ID</strong>
                                <p className="text-muted">{this.state.account.code}</p>
                                <hr size="1" />
                                <strong>Страна</strong>
                                <p className="text-muted">{this.state.account.country}</p>
                                <hr size="1" />
                                <strong>Дата регистрации</strong>
                                <p className="text-muted">{regDate.toLocaleDateString()}</p>
                                <hr size="1" />
                                <strong>Последняя активность</strong>
                                <p className="text-muted">{lastDate.toLocaleDateString()}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="box box-primary col-sm-4">
                            <div className="box-header">
                            <h3 className="box-title">Свойства</h3>
                            </div>
                            <div className="box-body">
                                {viewProps}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4">

                        <div className="box box-primary col-sm-4">
                            <div className="box-header">
                            <h3 className="box-title">Избранное</h3>
                            </div>
                            <div className="box-body">
                                {radios}
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    }

}