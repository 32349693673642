import React from 'react';
import axios from "axios";
import Config from "../services/config";

export default class ChangePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            changePassword: {
                new1: '',
                new2: '',
                prev_password: ''
            },
            error: {
                new1: false,
                new2: false,
                prev_password: false
            },
            logs: []
        }
        this.onEdit = this.onEdit.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    onEdit(e) {
        let id = e.target.id
        let change = this.state.changePassword;
        switch (id) {
            case 'password_new1':
                change.new1 = e.target.value;
                break;
            case 'password_new2':
                change.new2 = e.target.value;
                break;
            case 'password_prev':
                change.prev_password = e.target.value;
                break;
        }
        this.setState({
            changePassword: change
        })
    }

    saveChanges(e) {
        e.preventDefault()

        let change = this.state.changePassword
        let error = {
            new1: false,
            new2: false,
            prev_password: false
        };

        let hasError = false;

        if (change.new1 !== '' && change.new1 !== change.new2) {
            console.log(change);
            error.new2 = true;
            hasError = true;
        }
        if (change.prev_password === '') {
            error.prev_password = true;
            hasError = true;
        }

        if (!hasError) {
            let req = {
                new_password: change.new1,
                prev_password: change.prev_password
            };
            axios.post('/api/v3/login/changePassword', req, Config.getHeader()).then(data => {

            });
        }

    }

    render() {
        let error = this.state.error;

        let error2 = 'form-group';
        if (error.new2) {
            error2 = "form-group has-error";
        }

        let prevPasswordClass = 'form-group';
        if (error.prev_password) {
            prevPasswordClass = "form-group has-error";
        }

        let logs = this.state.logs.map(log => {

        });


        return <section className="content-header">
            <div className="box">
                <div className="box-header">
                    Изменение пароля {this.state.error.new2}
                </div>
                <div className="box-body">
                    <form className="form-horizontal">

                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Новый пароль</label>
                                    <div className="col-sm-6">
                                        <input className="form-control" type="text" id="password_new1" value={this.state.changePassword.new1} onChange={this.onEdit} placeholder="Новый пароль" />
                                    </div>
                                </div>
                                <div className={error2}>
                                    <label className="col-sm-3 control-label">Новый пароль (ещё раз)</label>
                                    <div className="col-sm-6">
                                        <input className="form-control" type="text" id="password_new2" value={this.state.changePassword.new2} onChange={this.onEdit} placeholder="Новый пароль (Повтор)" />
                                    </div>
                                </div>
                                <div className={prevPasswordClass}>
                                    <label className="col-sm-3 control-label">Старый пароль</label>
                                    <div className="col-sm-6">
                                        <input className="form-control" type="text" id="password_prev" value={this.state.changePassword.prev_password} onChange={this.onEdit} placeholder="Старый пароль" />
                                    </div>
                                </div>

                        <div className="form-group">
                            <div className="col-sm-offset-3 col-sm-9">
                                <button className="btn btn-primary" onClick={this.saveChanges}>Сохранить</button>
                            </div>
                        </div>
                    </form>
                </div>
                <table className="table table-bordered table-condensed table-hover">
                    <thead>
                        <tr>
                            <th>Дата</th>
                            <th>IP</th>
                        </tr>
                    </thead>
                    <tbody>
                    {logs}
                    </tbody>
                </table>
            </div>

        </section>
    }

}