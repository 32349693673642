import React from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { Link } from "react-router-dom";
import Config from "../services/config";
import modalStyle from "../services/consts";

export default class AccountList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accounts: [],
            filter: {
                column: 'login',
                order: "ASC"
            }
        };
        this.doSort = this.doSort.bind(this);
    }


    componentDidMount() {
        this.doLoad()
    }

    doLoad(column, order) {
        axios.get('/api/v3/accounts', Config.getHeaderWithParams({column: column, order: order})).then(data =>



                this.setState({
                    accounts: data.data.items,
                    filter: {
                        column: column,
                        order: order
                    },
                    pages: data.data.pages
                })
            , error => {

            });
    }

    doSort(e, column) {
        e.preventDefault()

        let order = this.state.filter.order
        if (column === this.state.filter.column) {
            if (order === 'ASC') {
                order = 'DESC'
            } else {
                order = 'ASC'
            }
        } else {
            order = 'ASC'
        }

        this.doLoad(column, order)
    }

    render() {

        let iconMap = {
            login: <i className="pull-right fa fa-fw fa-sort"> </i>,
            created_at: <i className="pull-right fa fa-fw fa-sort"> </i>,
            last_action: <i className="pull-right fa fa-fw fa-sort"> </i>,
            country: <i className="pull-right fa fa-fw fa-sort"> </i>,
            device: <i className="pull-right fa fa-fw fa-sort"> </i>
        };

        let sortColumn = this.state.filter.column || 'login'
        let sortOrder = this.state.filter.order || 'ASC'
        if (sortColumn && iconMap[sortColumn]) {
            if (sortOrder === 'ASC') {
                iconMap[sortColumn] = <i className="pull-right fa fa-fw fa-sort-amount-asc"> </i>
            } else {
                iconMap[sortColumn] = <i className="pull-right fa fa-fw fa-sort-amount-desc"> </i>
            }
        }



        let rows = this.state.accounts.map(row => {
            let created_at = null
            if (row.created_at) {
                let dt = new Date(row.created_at)
                created_at = <span>{dt.toLocaleDateString() + " " + dt.toLocaleTimeString()}</span>
            }

            let last_action = null
            if (row.last_action) {
                let dt = new Date(row.last_action)
                last_action = <span>{dt.toLocaleDateString() + " " + dt.toLocaleTimeString()}</span>
            }
            return <tr key={row.id}>
                <td>{row.code}</td>
                <td>{created_at}</td>
                <td>{last_action}</td>
                <td>{row.country}</td>
                <td>{row.device}</td>
                <td><Link to={"/AccountView/" + row.code} className="btn btn-success btn-sm"><i className="fa fa-edit"> </i> </Link></td>
                </tr>
            }
        );


        return <div>
            <section className="content-header">
                <h1>Список слушателей
                </h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <table className="table">
                            <thead>
                            <tr>
                                <td><a href={"#"}
                                       onClick={(e) => this.doSort(e, 'login')}><span>#</span> {iconMap['login']}</a>
                                </td>
                                <td><a href={"#"}
                                       onClick={(e) => this.doSort(e, 'created_at')}><span>Дата регистрации</span> {iconMap['created_at']}
                                </a></td>
                                <td><a href={"#"} onClick={(e) => this.doSort(e, 'last_action')}><span>Последняя активность</span> {iconMap['last_action']}
                                </a></td>
                                <th><a href={"#"}
                                       onClick={(e) => this.doSort(e, 'country')}><span>Страна</span> {iconMap['country']}
                                </a></th>
                                <th><a href={"#"}
                                       onClick={(e) => this.doSort(e, 'device')}><span>Устройство</span> {iconMap['device']}
                                </a></th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </div>
                    <div className="box-footer clearfix">
                        <ul className="pagination pagination-sm no-margin pull-right">
                            <li><a href="#">«</a></li>
                            <li><a href="#">1</a></li>
                            <li><a href="#">2</a></li>
                            <li><a href="#">3</a></li>
                            <li><a href="#">»</a></li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    }

}