import React from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import Config from "../services/config";
import RadioApi from '../services/RadioApi'
import modalStyle from '../services/consts';


export default class RadioList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            radios: [],
            total: 0,
            toDeleteOpen: false,
            radio: {
                name: ''
            },
            filter: {
                column: '',
                order: ''
            },
            defaultFilter: {
                column: 'name',
                order: 'ASC',
                search: ''
            },
            search: '',
            loading: false
        };
        this.confirmToDelete = this.confirmToDelete.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.togglePublished = this.togglePublished.bind(this);
        this.doSort = this.doSort.bind(this);
        this.onEditSearch = this.onEditSearch.bind(this);
        this.doSearch = this.doSearch.bind(this);
    }

    onEditSearch(e) {
        this.setState({
            search: e.target.value
        });
    }

    doSearch(e) {
        this.loadRadioList(this.state.filter.column, this.state.filter.order);
    }

    componentDidMount() {

        let column = RadioApi.storage_get('radio_list', 'column') || this.state.defaultFilter.column;
        let order = RadioApi.storage_get('radio_list', 'order') || this.state.defaultFilter.order;
        let search = RadioApi.storage_get('radio_list', 'search') || this.state.defaultFilter.search;

        console.log('saved filter', column, order, search);

        this.setState({
            filter: {
                column: column,
                order: order
            },
            search: search
        });

        this.loadRadioList(column, order);
    }

    loadRadioList(column, order) {
        this.setState({
            loading: true
        });

        (async () => {


            const result = await RadioApi.loadRadioList({ column: column, order: order, search: this.state.search })
            this.setState({
                radios: result.items,
                total: result.total,
                filter: {
                    column: column,
                    order: order
                },
                loading: false
            });

            RadioApi.storage_set('radio_list', 'column', column)
            RadioApi.storage_set('radio_list', 'order', order)
            RadioApi.storage_set('radio_list', 'search', this.state.search)
        })();
    }

    confirmToDelete(e, radio) {
        this.setState({
            toDeleteOpen: true,
            radio: radio
        });
    }

    doSort(e, column) {
        e.preventDefault()

        let order = this.state.filter.order
        if (column === this.state.filter.column) {
            if (order === 'ASC') {
                order = 'DESC'
            } else {
                order = 'ASC'
            }
        } else {
            order = 'ASC'
        }

        this.loadRadioList(column, order)

    }

    doDelete(e, accept) {
        this.setState({
            toDeleteOpen: false
        });
        if (accept) {
            axios.delete('/api/v3/radio/' + this.state.radio.id, Config.getHeader())
                .then(data => this.loadRadioList());
            ;
        }
    }

    togglePublished(e, radio) {
        let action = radio.published === true ? '/unpublish' : '/publish';
        axios.get('/api/v3/radio/' + radio.id + action, Config.getHeader())
            .then(data => this.loadRadioList());
    }

    checkStream(e, radio) {
        axios.get('/api/v3/radio/' + radio.id + '/checkStream', Config.getHeader())
            .then(data => this.loadRadioList());
    }

    render() {

        let rows = this.state.radios.map(radio => {

            let published
            if (radio.published) {
                published = <button className="btn btn-success btn-sm" onClick={(e) => this.togglePublished(e, radio)}><i className="fa fa-check-circle-o" /></button>
            } else {
                published = <button className="btn btn-default btn-sm" onClick={(e) => this.togglePublished(e, radio)}><i className="fa fa-close" /></button>
            }

            let c = new Date(radio.created_at);
            let checkTm = undefined
            if (radio.check_tm) {
                let dt = new Date(radio.check_tm)
                checkTm = <span className="label label-default">{dt.toLocaleDateString()} {c.toLocaleTimeString()}</span>;
            }

            /*
            let enabled;
            if (radio.enabled) {
                enabled = <i className="fa fa-check-circle-o" title={checkTitle}> </i>
            } else {
                enabled = <i className="fa fa-minus-circle" title={checkTitle}> </i>
            }
            */
            let urls = radio.urls || [];
            let enabled = urls.map(rs => {
                let checkTitle = undefined;
                if (rs.check_tm) {
                    let dt = new Date(rs.check_tm)
                    checkTitle = dt.toLocaleDateString() + " " + dt.toLocaleTimeString()
                }

                if (rs.checked) {
                    return <small className="label bg-green" key={checkTitle + ''}><i className="fa fa-check-circle-o" title={checkTitle} > </i></small>
                } else {
                    return <small className="label bg-red" key={checkTitle + ''}><i className="fa fa-minus-circle" title={checkTitle} > </i></small>
                }
            });


            let genres = [];
            if (radio.genres) {
                radio.genres.forEach(genre => {
                    genres.push(<span className="label label-info" key={genre.name}>{genre.name}</span>)
                    genres.push(<span key={genre.name + "z"}>&nbsp;</span>)
                })
            }

            let country = undefined;
            if (radio.country) {
                country = radio.country.name;
            }

            return <tr key={radio.id}>
                <td><img src={radio.logo} width={16} title={radio.name} alt={radio.name} /></td>
                <td><Link to={"/RadioForm/" + radio.id}>{radio.name}</Link></td>
                <td>{radio.url}</td>
                <td>{genres}</td>
                <td>{country}</td>
                <td>{published}</td>
                <td><span className="pull-rigth-container">{enabled}</span></td>
                <td><span className="label label-info">{c.toLocaleDateString()}</span></td>
                <td>
                    <Link to={"/RadioForm/" + radio.id} className="btn btn-success btn-sm"><i className="fa fa-edit"> </i> </Link>
                </td>
                <td>
                    <button className="btn btn-danger btn-sm" attr-id={radio.id} onClick={(e) => this.confirmToDelete(e, radio)}><i className="fa fa-trash"> </i></button>
                </td>
                <td>
                    <button className="btn btn-info btn-sm" attr-id={radio.id} onClick={(e) => this.checkStream(e, radio)} title="Проверить"><i className="fa fa-bolt"> </i></button>
                </td>
            </tr>
        });

        let iconMap = {
            name: <i className="pull-right fa fa-fw fa-sort"> </i>,
            url: <i className="pull-right fa fa-fw fa-sort"> </i>,
            genre: <i className="pull-right fa fa-fw fa-sort"> </i>,
            country: <i className="pull-right fa fa-fw fa-sort"> </i>,
            published: <i className="pull-right fa fa-fw fa-sort"> </i>,
            enabled: <i className="pull-right fa fa-fw fa-sort"> </i>,
            created_at: <i className="pull-right fa fa-fw fa-sort"> </i>
        };

        let sortColumn = this.state.filter.column || 'name'
        let sortOrder = this.state.filter.order || 'ASC'
        if (sortColumn && iconMap[sortColumn]) {
            if (sortOrder === 'ASC') {
                iconMap[sortColumn] = <i className="pull-right fa fa-fw fa-sort-amount-asc"> </i>
            } else {
                iconMap[sortColumn] = <i className="pull-right fa fa-fw fa-sort-amount-desc"> </i>
            }
        }

        let thNoWrap = {
            whiteSpace: 'nowrap'
        }

        let loading = this.state.loading && <div className="overlay">
            <i className="fa fa-refresh fa-spin"></i>
        </div>

        return <div>
            <section className="content-header">
                <h1>Список радио-станций
                    <div className="pull-right">
                        <Link to="/UploadCSV" className="btn btn-success btn-sm">
                            <i className="fa fa-plus-circle" /> Добавить CSV
                        </Link>
                        &nbsp;
                        <Link to="/RadioForm" className="btn btn-success btn-sm">
                            <i className="fa fa-plus-circle" /> Добавить станцию
                        </Link>
                    </div>
                </h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-header">
                        <form className="form">
                        <div className="form-group col-sm-4">
                            <div className="input-group">
                                <input type="text" className="form-control" id="s_search" placeholder="Поиск" onChange={this.onEditSearch} />
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-info" onClick={this.doSearch}><i className="fa fa-search"> </i> Поиск</button>
                                </span>
                            </div>
                        </div>
                        </form>
                    </div>
                    <div className="box-body table-responsive">
                        <table className="table table-bordered table-condensed table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th><a href={"#"} onClick={(e) => this.doSort(e, 'name')}><span>Наименование</span> {iconMap['name']}</a></th>
                                    <th><a href={"#"} onClick={(e) => this.doSort(e, 'url')}>Ссылка {iconMap['url']}</a></th>
                                    <th>Жанр</th>
                                    <th><a href={"#"} onClick={(e) => this.doSort(e, 'country')}>Страна  {iconMap['country']}</a></th>
                                    <th><a href={"#"} onClick={(e) => this.doSort(e, 'published')}>Опубликован {iconMap['published']}</a></th>
                                    <th style={thNoWrap}><a href={"#"} onClick={(e) => this.doSort(e, 'enabled')}>Проверка потока {iconMap['enabled']}</a></th>
                                    <th><a href={"#"} onClick={(e) => this.doSort(e, 'created_at')}>Добавлен {iconMap['created_at']}</a></th>
                                    <th colSpan={2} width={"1%"}>...</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                    {loading}
                </div>

            </section>
            <Modal isOpen={this.state.toDeleteOpen} onRequestClose={(e) => this.doDelete(e, false)} style={modalStyle} >
                <h3>Удалить {this.state.radio.name}?</h3>
                <div className="row">
                    <div className="col-sm-6">
                        <button className="btn btn-default" onClick={(e) => this.doDelete(e, false)}>Не удалять</button>
                    </div>
                    <div className="col-sm-6">
                        <button className="btn btn-primary" onClick={(e) => this.doDelete(e, true)}>Удалить</button>
                    </div>
                </div>
            </Modal>
        </div>
    }

}