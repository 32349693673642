
import Config from "./config";
import axios from "axios";

const prefix = '/api/v3'
const api = prefix + '/radio'

const RadioApi = {
    loadRadioList,
    loadRadio,
    saveRadio,
    deleteRadio,
    storage_set,
    storage_get,
    load_radio_for_edit,
    load_radio_for_new,
    getAccountInfo
}

async function loadRadioList(filter) {
    const response = await axios.get(api, Config.getHeaderWithParams(filter));
    return response.data;
}

async function loadRadio(id) {
    const response = await axios.get(api + "/" + id, Config.getHeader());
    return response.data;
}

async function saveRadio(radio) {
    const response = await axios.post(api, radio, Config.getHeader());
    return response.data;
}

async function deleteRadio(id) {
    const response = await axios.delete(api + "/" + id, Config.getHeader());
    return response.data;
}

async function getAccountInfo(id) {
    const response = await axios.get(prefix + "/accounts/" + id, Config.getHeader());
    return response.data;
}

function storage_set(space, key, value) {
    localStorage.setItem(space + '.' + key, value);
}

function storage_get(space, key) {
    localStorage.getItem(space + '.' + key);
}

async function loadCity() {
    const response = await axios.get(prefix + "/city", Config.getHeader());
    return response.data;
}

async function loadCountry() {
    const response = await axios.get(prefix + "/country", Config.getHeader());
    return response.data;
}

async function loadGenre() {
    const response = await axios.get(prefix + "/genre", Config.getHeader());
    return response.data;
}

async function load_radio_for_edit(id) {
    const radio = loadRadio(id)
    const city = loadCity();
    const country = loadCountry();
    const genre = loadGenre();
    return {
        radio: await radio,
        cityList: await city,
        countryList: await country,
        genreList: await genre
    };
}

async function load_radio_for_new() {
    const city = loadCity();
    const country = loadCountry();
    const genre = loadGenre();
    return {
        cityList: await city,
        countryList: await country,
        genreList: await genre
    };
}

export default RadioApi;