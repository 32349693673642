import React from 'react';
import axios from 'axios';
import Config from "../services/config";
import RadioApi from "../services/RadioApi";

export default class RadioForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            radio: {
                id: 0,
                name: '',
                address: '',
                url: '',
                urls: [{
                    id: 0,
                    url: '',
                    index: 0,
                    deleted: false
                }],
                logo: '',
                genre_id1: 0,
                genre_id2: 0,
                genre_id3: 0,
                country_id: 0,
                city_id: 0,
                city_name: '',
                logo_data: '',
                description: '',
                tags: [],
                tag: '',
                cities: []
            },
            genres: [],
            countries: [],
            logoData: undefined
        }
        this.onEdit = this.onEdit.bind(this)
        this.onEditTag = this.onEditTag.bind(this)
        this.onKeyPressTag = this.onKeyPressTag.bind(this)
        this.saveRadio = this.saveRadio.bind(this)
        this.uploadImage = this.uploadImage.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.onUrlEdit = this.onUrlEdit.bind(this)
        this.addUrlRecord = this.addUrlRecord.bind(this)
        this.delUrlRecord = this.delUrlRecord.bind(this)
    }

    uploadImage(e) {
        e.preventDefault()
        if (e.target.files.length > 0) {
            let reader = new FileReader()
            let file = e.target.files[0]
            reader.onloadend = () => {
                //console.log(reader.result)
                this.setState({
                    logoData: reader.result
                })
            }
            reader.readAsDataURL(file)
        }
    }

    componentDidMount() {

        let id = this.props.match.params.id || false;

        if (id) {
            (async () => {

                const allResponse = await RadioApi.load_radio_for_edit(id);

                const radio = allResponse.radio;

                radio.genre_id1 = 0;
                radio.genre_id2 = 0;
                radio.genre_id3 = 0;
                radio.country_id = 0;

                if (radio.genres) {
                    if (radio.genres.length > 0) {
                        radio.genre_id1 = radio.genres[0].id;
                    }
                    if (radio.genres.length > 1) {
                        radio.genre_id2 = radio.genres[1].id;
                    }
                    if (radio.genres.length > 2) {
                        radio.genre_id3 = radio.genres[2].id;
                    }
                }

                if (radio.country) {
                    radio.country_id = radio.country.id;
                }

                this.setState({
                    radio: radio,
                    genres: allResponse.genreList,
                    countries: allResponse.countryList,
                    cities: allResponse.cityList
                });
            })()
        } else {
            (async () => {
                const allResponse = await RadioApi.load_radio_for_new(id);
                let radio = this.state.radio;

                allResponse.countryList.forEach(country => {
                    if (country.is_default === true) {
                        radio.country_id = country.id;
                    }
                });

                this.setState({
                    genres: allResponse.genreList,
                    countries: allResponse.countryList,
                    cities: allResponse.cityList
                });
            })()
        }

    }

    saveRadio(e) {
        e.preventDefault()
        let req = this.state.radio
        req.logo_data = this.state.logoData
        axios.post('/api/v3/radio', req, Config.getHeader()).then(data => {
            this.props.history.push(`/RadioList`)
        }, error => {

        })
    }


    onEdit(e) {
        let id = e.target.id;
        let radio = this.state.radio;
        switch (id) {
            case 'radio_name':
                radio.name = e.target.value;
                break;
            case 'radio_address':
                radio.address = e.target.value;
                break;
            case 'radio_url':
                radio.url = e.target.value;
                break;
            case 'radio_description':
                radio.description = e.target.value;
                break;
            case 'radio_logo':
                radio.logo = e.target.value;
                break;
            case 'radio_genre1':
                radio.genre_id1 = e.target.value;
                break;
            case 'radio_genre2':
                radio.genre_id2 = e.target.value;
                break;
            case 'radio_genre3':
                radio.genre_id3 = e.target.value;
                break;
            case 'radio_country':
                radio.country_id = parseInt(e.target.value);
                break;
            case 'radio_city':
                radio.city_id = parseInt(e.target.value);
                break;
            case 'radio_city_name':
                radio.city_name = e.target.value;
                break;
            case 'radio_tag':
                radio.tag = e.target.value;
                break;
        }
        this.setState({
            radio: radio
        })
    }

    onUrlEdit(e, index) {
        let radio = this.state.radio;
        let stream = radio.urls[index];
        stream.url = e.target.value;
        radio.urls[index] = stream;
        this.setState({
            radio: radio
        });
    }

    addUrlRecord(e) {
        let radio = this.state.radio;
        let urls = (radio.urls || []);
        let count = urls.length;

        let record = {id:0, url: '', index: count, deleted: false};

        radio.urls = urls.concat([record]);
        this.setState({
            radio: radio
        });
    }

    delUrlRecord(e, index) {
        this.state.radio.urls.map(stream => {
            if (stream.index === index) {
                stream.deleted = true;
            }
        });
        this.setState({
            radio: this.state.radio
        });
    }

    onEditTag(e) {
        let radio = this.state.radio;
        radio.tag = e.target.value;
        this.setState({
            radio: radio
        });
    }

    onKeyPressTag(e) {
        if (e.which === 13) {
            let radio = this.state.radio;
            let tag = radio.tag;
            if (tag) {
                let tags = radio.tags || []
                tags.push(tag)
                radio.tags = tags
                radio.tag = ''
                this.setState({
                    radio: radio
                })
            }
        }
    }

    removeTag(e, deletedTag) {
        let newTags = [];
        let radio = this.state.radio 
        let tags = radio.tags || [];
        tags.forEach(tag => {
            if (tag !== deletedTag) {
                newTags.push(tag)
            }
        })
        radio.tags = newTags;
        this.setState({
            radio: radio
        });
    }

    render() {

        const hiddenInputFile = {
            display: "none !important"
        }

        let genreOptions = [{ id: 0, name: '--' }].concat(this.state.genres).map(genre => <option key={genre.id}
            value={genre.id}>
            {genre.name}
        </option>);

        let countryOptions = [{ id: 0, name: '--' }].concat(this.state.countries).map(country => <option key={country.id}
            value={country.id}>
            {country.name}
        </option>);

        let cityList = this.state.cities || [];
        let filteredCityList = cityList.filter(city => {
            let $id = this.state.radio.country_id || 0;
            if ($id > 0) {
                return city.country_id === $id;
            }
            return true;
        }); 
        let cityOptions = [{id:0, name: ''}].concat(filteredCityList).map(c => <option key={c.id} value={c.id}>
            {c.name}
        </option>)



        let image = undefined;
        if (this.state.logoData !== undefined) {
            image = <img src={this.state.logoData} width={400} />
        } else {
            image = <img src={this.state.radio.logo} width={400} />
        }

        let tags = this.state.radio.tags || [];

        let tagList = tags.map(tag => <button className="btn btn-sm btn-info" onClick={e => this.removeTag(e, tag)}>
            <i className="fa fa-close"> </i> {tag}
        </button>

        )

        let urls = this.state.radio.urls || [];
        let uCount = urls.length;
        let urlsComp = urls.filter(x => x.deleted == false).map((stream) => {
            return <div className="form-group" key={stream.id}>
                    <label className="col-sm-3 control-label">Адрес потока</label>
                    <div className="col-sm-9">
                        <div className="input-group">
                            <input className="form-control" type="text" id={"radio_url" + stream.index}
                                value={stream.url} onChange={(e) => this.onUrlEdit(e, stream.index)} placeholder={"Адрес потока " + stream.index} />
                            <span className="input-group-btn">
                                {stream.index == 0 && <button type="button" className="btn btn-primary btn-flat" onClick={this.addUrlRecord}>
                                    <i className="fa fa-plus" />
                                </button>}
                                {stream.index > 0 && <button type="button" className="btn btn-warning btn-flat" onClick={(e) => this.delUrlRecord(e, stream.index)}>
                                    <i className="fa fa-minus" />
                                </button>}
                            </span>
                        </div>
                    </div>
                </div>
        });

        return <div>
            <section className="content-header">
                <h1>
                    Добавление радио-станции
                </h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-header">

                    </div>
                    <div className="box-body">
                        <form className="form-horizontal">
                            <div className="row">
                                <div className="col-xs-12 col-sm-10 col-md-8">
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">Наименование</label>
                                        <div className="col-sm-9">
                                            <div className="input-group">
                                                <input className="form-control" type="text" id="radio_name"
                                                    value={this.state.radio.name} onChange={this.onEdit}
                                                    placeholder="Наименование" />
                                                <span className="input-group-btn">
                                                    <button type="button" className="btn btn-primary btn-flat"><i
                                                        className="fa fa-language" /></button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">Адрес сайта</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" placeholder="Адрес сайта" 
                                                value={this.state.radio.address} 
                                                onChange={this.onEdit} id="radio_address" />
                                        </div>
                                    </div>
                                    {urlsComp}
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">Логотип</label>
                                        <div className="col-sm-9">
                                            <label className="btn btn-info">
                                                <input type="file" hidden onChange={this.uploadImage} /> Загрузить
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">Жанр</label>
                                        <div className="col-sm-3">
                                            <select className="form-control" id="radio_genre1" onChange={this.onEdit}
                                                value={this.state.radio.genre_id1}>
                                                {genreOptions}
                                            </select>
                                        </div>
                                        <div className="col-sm-3">
                                            <select className="form-control" id="radio_genre2" onChange={this.onEdit}
                                                value={this.state.radio.genre_id2}>
                                                {genreOptions}
                                            </select>
                                        </div>
                                        <div className="col-sm-3">
                                            <select className="form-control" id="radio_genre3" onChange={this.onEdit}
                                                value={this.state.radio.genre_id3}>
                                                {genreOptions}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">Страна</label>
                                        <div className="col-sm-9">
                                            <select className="form-control" id="radio_country" onChange={this.onEdit}
                                                value={this.state.radio.country_id}>
                                                {countryOptions}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">Город</label>
                                        <div className="col-sm-4">
                                            <select className="form-control" id="radio_city" onChange={this.onEdit}
                                                value={this.state.radio.city_id}>
                                                {cityOptions}
                                            </select>
                                        </div>
                                        <div className="col-sm-5">
                                        <input type="text" className="form-control" placeholder="Город" 
                                                value={this.state.radio.city_name} 
                                                onChange={this.onEdit} id="radio_city_name" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">Описание</label>
                                        <div className="col-sm-8">
                                            <textarea className="form-control" type="text" id="radio_description"
                                                value={this.state.radio.description} onChange={this.onEdit}
                                                placeholder="Описание"></textarea>
                                        </div>
                                        <div className="col-sm-1">
                                            <button type="button" className="btn btn-primary btn-flat"><i className="fa fa-language" /></button>
                                        </div>
                                    </div>
                                    
                                    {/* <div className="form-group">
                                        <label className="col-sm-3 control-label">Теги</label>
                                        <div className="col-sm-9">
                                            <input className="form-control" type="text" id="radio_tag"
                                                value={this.state.radio.tag}
                                                onChange={this.onEditTag} onKeyPress={this.onKeyPressTag} />
                                        </div>
                                    </div> */}
                                    
                                    <div className="form-group">
                                        <div className="col-sm-offset-3 col-sm-9">
                                            {tagList}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-sm-offset-3 col-sm-6">
                                            <button className="btn btn-primary" onClick={this.saveRadio}>Сохранить</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">

                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-8">
                                    <div className="col-sm-offset-3 col-sm-6">
                                        {image}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </section>
        </div>
    }

}